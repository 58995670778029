.RowTxtLeft {
  display: flex;
  justify-content: center;
  margin: 0 0 25px 0;
}

.RowTxtLeft--container {
  display: flex;
  justify-content: space-between;
  margin: 0;
  background-color: #f2f2f2;
  width: 1140px;
}

.RowTxtLeft--Left {
  width: 440px;
  padding: 60px 0 0 70px;
}

.RowTxtLeft--Right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 130px;
}

.img-1 {
  object-fit: cover;
  height: 430px;
  width: 350px;
}

button {
  margin: 0 0 55px 0;
}

#medal {
  object-fit: contain !important;
  height: 250px !important;
  width: 250px !important;
}

#medal--container {
  align-items: center !important;
  justify-content: center !important;
  padding-right: 170px;
}

@media screen and (max-width: 480px) {
  .RowTxtLeft--container {
    width: 90%;
  }

  .RowTxtLeft--Left {
    padding: 20px;
  }

  .RowTxtLeft--Right {
    display: none;
  }

  button {
    margin: 0;
  }

  .h2--dark {
    font-size: 40px;
    line-height: 38px;
    width: 300px;
    white-space: pre-wrap;
  }

  p {
    padding: 0 0 30px 0;
    font-size: 20px;
    line-height: 26px;
  }

  .img-1 {
    display: none;
  }
}
