.pghead {
  display: flex;
  justify-content: center;
  margin: 60px 0 40px 0;
}

.pghead--container {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  width: 1140px;
}

.pghead--text {
  padding: 0;
  text-align: left;
}

@media screen and (max-width: 480px) {
  .pghead {
    margin: 40px 0 25px 0;
  }

  .pghead--container {
    width: 100%;
    justify-content: center;
  }
}
