.about {
  display: flex;
  justify-content: center;
}

.about--container {
  display: flex;
  justify-content: space-between;
  width: 1140px;
}

.about--left {
  display: flex;
  justify-content: flex-start;
  width: 360px;
  flex-direction: column;
}

.about--left > .scrolltocontact-nav {
  justify-content: flex-start;
  margin: 0;
}

.about--right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 460px;
  transform: translate(0, -100px);
  object-fit: fill;
  background-image: url(./images/melmap.png);
  background-size: 140%;
  background-position: -110px -45px;
}

.about--left > p {
  padding: 0 0 50px 0;
}

@media screen and (max-width: 480px) {
  .about--container {
    width: 90%;
    flex-direction: column;
  }

  .about--left {
    width: 100%;
  }

  .about--right {
    width: 100%;
    height: 220px;
    transform: translate(0, 0);
    background-position: -90px -45px;
    margin-bottom: 50px;
  }

  .about--left > p {
    padding: 0 0 5px 0;
  }
}
