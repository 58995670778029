.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgb(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal--container {
  display: flex;
  padding: 45px;
  background-color: white;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}

.closeicon {
  position: absolute;
  width: 35px;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .modal {
    align-items: flex-start;
    overflow-y: auto;
  }

  .modal--container {
    padding: 20px;
    margin: 15px 15px 130px 15px;
  }
}
