.tile {
  display: flex;
  justify-content: center;
  margin: 0 25px 25px 0;
  cursor: pointer;
}

.tile--container {
  display: flex;
  justify-content: Center;
  align-items: Center;
  margin: 0;
  width: 363px;
  height: 200px;
  background-size: cover;
}

.tileh2 {
  margin: 0;
  color: white;
  opacity: 80%;
  text-align: center;
}

.tileh2--container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bd8983;
  height: 100%;
  width: 100%;
}

.modal--container--inner {
  display: flex;
}

.modal--left {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 430px;
  height: 100%;
}

.modal--right {
  width: 400px;
}

.modal--right--inner {
  padding-left: 40px;
}

#modalh1 {
  text-align: left;
  margin-top: 10px;
}

#modalp {
  font-size: 18px;
  line-height: 24px;
}

.prodimg {
  width: 100%;
  height: 510px;
}

@media screen and (max-width: 480px) {
  .tile {
    margin: 0;
  }

  .tile--container {
    width: 90vw;
    margin-bottom: 18px;
  }

  .modal--container--inner {
    flex-direction: column;
    padding: 0;
  }

  .modal--left {
    width: 100%;
    padding: 45px 0 0 0;
    height: 350px;
  }

  .modal--right {
    width: 100%;
  }

  .modal--right--inner {
    padding: 0 15px 0 15px;
  }

  .prodimg {
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
  }

  #modalh1 {
    padding: 0 30px 20px 0;
    margin-top: 35px;
  }
}
