.herophoto {
  display: flex;
  justify-content: center;
  margin: 60px 0 25px 0;
}

.herophoto-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 429px;
  width: 1140px;
  background-image: url(./images/Jola_crop.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

h1 {
  font-family: "proxima-nova", sans-serif;
  line-height: 75px;
  font-size: 72px;
  font-weight: 700;
  text-align: end;
  width: 100%;
  padding: 0 30px 20px 0;
  margin: 0;
  color: #333333;
}

@media screen and (max-width: 480px) {
  .herophoto {
    margin: 25px 0 25px 0;
  }

  .herophoto-container {
    width: 90%;
    height: 370px;
    justify-content: flex-start;
  }

  h1 {
    font-size: 51px;
    line-height: 54px;
    width: 90%;
    text-align: start;
    padding-left: 20px;
  }
}
