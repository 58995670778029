.formsuccess {
  display: flex;
  justify-content: center;
  width: 103%;
}

.formsuccess--container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.formsuccess--left {
  display: flex;
  padding: 0 20px 0 5px;
}

.formsuccess--right {
  display: flex;
  align-items: center;
}

.formsuccess--right > p {
  font-size: 16px;
  line-height: 20px;
  padding: 0;
}

@media screen and (max-width: 480px) {
  .formsuccess {
    width: 100%;
  }

  .formsuccess--right > p {
    font-size: 14px;
    line-height: 18px;
    padding: 0;
  }
}
