.ptcont {
  display: flex;
  justify-content: center;
  margin: 20px 0 200px 0;
}

.ptcont--container {
  display: flex;
  flex-direction: row;
  justify-content: flex start;
  flex-wrap: wrap;
  margin: 0;
  width: 1165px;
  padding-left: 25px;
}

@media screen and (max-width: 480px) {
  .ptcont--container {
    width: 90%;
    padding: 0;
  }
}
