.bbox {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00586c;
  height: 157px;
  width: 363px;
  margin-bottom: 100px;
}

.bbox--container {
  padding: 5px 30px 5px 30px;
}

.h3white {
  color: #f2f2f2;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .bbox {
    width: 100%;
    height: 120px;
    margin-bottom: 15px;
    align-items: center;
  }

  .bbox--container {
    padding: 0;
    width: 60%;
  }
}
