.navbar {
  background-color: #00586c;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 16px;
  position: sticky;
  top: 0;
  z-index: 999;
  transition: ease 0.3s;
}

.navbar.active {
  height: 100px;
  transition: ease 0.2s;
}

.navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 31px;
  width: 1140px;
  margin: 0 150px 0 150px;
}

.navbar-logo {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
}

.nav-menu {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  margin: 0;
}

.nav-links {
  color: #fff;
  display: flex;
  font-family: "proxima-nova", sans-serif;
  font-weight: 700;
  text-decoration: none;
  align-items: flex-end;
  text-align: right;
  margin: 0 0 0 3rem;
}

.nav-links:hover {
  color: #bd8983;
  transition: all 0.1s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.scrolltocontact-nav {
  color: #fff;
  display: flex;
  font-family: "proxima-nova", sans-serif;
  font-weight: 700;
  text-decoration: none;
  align-items: flex-end;
  text-align: right;
  margin: 0 0 0 48px;
}

@media screen and (max-width: 480px) {
  .navbar {
    height: 100px;
    width: 100%;
  }

  .nav-menu {
    width: 100%;
    height: 100vh;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    top: 100px;
    left: -150%;
    opacity: 1;
    transition: all 0.2s ease;
    padding: 40px 0 0 0;
  }

  .nav-menu.active {
    display: flex;
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.2s ease;
    z-index: 1;
  }

  .nav-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .nav-links {
    text-align: center;
    padding: 2rem 0 2rem 0;
    width: 100%;
    margin: 0;
    justify-content: center;
  }

  .nav-links:hover {
    background-color: #bd8983;
    color: #ffff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(1%, 62%) scale(0.8);
  }

  .menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-130%, 120%);
    font-size: 1.9rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    transform: translate(-10px, 0);
  }

  .scrolltocontact-nav {
    padding: 2rem 0 2rem 0;
    margin: 0;
  }
}
