.calltoaction--main {
  display: flex;
  justify-content: center;
  margin: 0 0 0 0;
}

.calltoaction--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 1140px;
  height: 350px;
  background-color: #f2f2f2;
}

.h2brown {
  font-family: "proxima-nova", sans-serif;
  text-align: center;
  font-size: 35px;
  line-height: 38px;
  font-weight: 800;
  color: #bd8983;
  padding: 0 100px 0 110px;
}

@media screen and (max-width: 480px) {
  .calltoaction--wrapper {
    width: 90%;
  }

  .h2brown {
    padding: 0 50px 0 50px;
  }
}
