.formmain {
  display: flex;
  justify-content: center;
  margin: 100px 0 100px 0;
}

.form--container {
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 1140px;
}

.formleft {
  padding: 0 0 0 72px;
  width: 360px;
}

.formright {
  display: flex;
  justify-content: flex-end;
  margin: 0 40px 0 0;
}

.contactform {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 510px;
}

input {
  width: 100%;
  height: 40px;
  background-color: #e0e0e0;
  border: none;
  font-size: 18px;
  font-family: "Neue-Haas-Unica-W1G", sans-serif;
  font-weight: 400;
  padding-left: 12px;
}

textarea {
  width: 100%;
  height: 150px;
  background-color: #e0e0e0;
  border-radius: none;
  margin: 0 0 30px 0;
  resize: none;
  border: none;
  font-size: 18px;
  font-family: "Neue-Haas-Unica-W1G", sans-serif;
  font-weight: 400;
  padding-left: 12px;
  padding-top: 7px;
}

label {
  font-family: "Neue-Haas-Unica-W1G", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 36.14px;
  margin: 0;
  padding: 5px 0 0 0;
}

.submitbtn {
  background-color: #bd8983;
  color: white;
  height: 45px;
  padding: 0 65px 0 65px;
  font-family: "Neue-Haas-Unica-W1G", sans-serif;
  font-size: 18px;
  font-weight: 500;
  border-radius: 3px;
  border: none;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 103%;
  margin-bottom: 15px;
  outline: none;
  transition: ease 0.2s;
}

.submitbtn:hover {
  box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.35);
  transform: scale(0.97);
  transition: ease 0.2s;
}

.contactinfo {
  display: flex;
  flex-direction: row;
}

.contactinfo--container {
  display: flex;
  flex-direction: column;
}

.icon--contact {
  width: 35px;
  margin: 0 15px 0 0;
}

.cont-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 20px 0;
}

.forminfop {
  padding: 0;
  margin-bottom: 40px;
}

.continfop {
  font-size: 18px;
  font-weight: 700;
  line-height: 0;
  padding: 0;
}

@media screen and (max-width: 480px) {
  .formmain {
    margin: 50px 0 30px 0;
  }

  .form--container {
    flex-direction: column;
  }

  .form--container {
    width: 90vw;
    margin: 0;
  }

  .formleft {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .formright {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .formleft > h2 {
    font-family: "proxima-nova", sans-serif;
    padding: 0 0 30px 0;
  }

  .formleft > p {
    display: none;
  }

  .continfop {
    font-size: 15.5px;
  }

  input {
    padding: 0;
  }

  textarea {
    padding: 0;
  }

  .submitbtn {
    padding: 0;
    width: 100%;
  }
}
