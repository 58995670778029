.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 0;
  width: 100%;
  background-color: #828282;
}

.footer--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  height: 100px;
  width: 1140px;
}

.footer--left {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.socialicons {
  display: flex;
  justify-content: space-between;
  width: 130px;
}

#ReactLogo2 {
  transform: scale(-7);
}

@media screen and (max-width: 480px) {
  .footer--container {
    width: 90%;
    justify-content: center;
  }

  .footer--left {
    display: none;
  }
}
