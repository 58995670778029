.App {
  margin: 0;
  padding: 0;
}

p {
  font-family: "Neue-Haas-Unica-W1G", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 36.14px;
  margin: 0;
  padding: 0 0 63px 0;
}

.h2--dark {
  font-family: "proxima-nova", sans-serif;
  font-size: 48px;
  line-height: 55px;
  font-weight: 700;
  padding: 0 0 13px 0;
}

h3 {
  font-family: "proxima-nova", sans-serif;
  font-size: 35px;
  line-height: 38px;
  font-weight: 700;
}

.cookie--container {
  background-color: rgb(0, 0, 0, 0.7);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(6px);
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 28%;
  right: 28%;
  position: fixed;
  height: fit-content;
  min-height: 70px;
  margin-bottom: 30px;
  z-index: 1100;
  padding: 20px;
}

.cookie--text {
  color: white;
  font-family: "Neue-Haas-Unica-W1G", sans-serif;
  font-size: 14px;
  line-height: auto;
  padding: 0 20px 0 0;
}

.cookie--button {
  height: 50px;
  width: 120px;
  border-radius: 3px;
  border: none;
  margin: 0;
  background-color: #bd8983;
  color: white;
  font-family: "Neue-Haas-Unica-W1G", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.cookie--btn--wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.privpolicy {
  color: aqua;
}

@media screen and (max-width: 480px) {
  h3 {
    font-size: 25px;
    line-height: 28px;
  }

  .cookie--container {
    flex-direction: column;
    left: 10%;
    right: 10%;
  }

  .cookie--text {
    padding: 0 0 20px 0;
  }
}
