.rowtwo--container {
  display: flex;
  justify-content: center;
  margin: 0 0 25px 0;
}

.rowtwo--left {
  width: 557px;
  background-image: url("./images/baus.jpg");
  height: 429px;
  margin-right: 13px;
  background-size: fill;
  background-repeat: no-repeat;
  background-position: -200px -60px;
}

.rowtwo--right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 557px;
  background-color: #bd8983;
  color: white;
  height: 429px;
  margin-left: 13px;
  cursor: pointer;
  transition: ease 0.2s;
}

.rowtwo--right:hover {
  box-shadow: 0px 3px 22px 0px rgba(126, 90, 90, 0.3);
  transform: scale(1.01);
  transition: ease 0.2s;
}

h2 {
  font-family: "proxima-nova", sans-serif;
  font-size: 48px;
  padding: 0 30px 20px 0;
  margin: 0;
}

.vproj-link {
  text-decoration: none;
}

@media screen and (max-width: 480px) {
  .rowtwo--left {
    display: none;
    justify-content: center;
    margin: 0;
  }

  .rowtwo--right {
    width: 90%;
    margin: 0 0 0 18px;
    height: 300px;
  }

  .rowtwo--right--container {
    padding-right: 50px;
  }

  h2 {
    padding: 0 0 20px 30px;
    font-size: 40px;
    line-height: 44px;
  }
}
