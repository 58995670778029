.bbcont--wrapper {
  display: flex;
  justify-content: center;
}

.bbcont {
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 1140px;
}

.bbheading h2 {
  font-family: "proxima-nova", sans-serif;
  text-align: center;
  margin: 100px 0 60px 0;
}

@media screen and (max-width: 480px) {
  .bbcont--wrapper {
    flex-direction: column;
    align-items: center;
  }

  .bbcont {
    width: 90%;
    flex-direction: column;
    justify-content: center;
  }

  .bbheading > h2 {
    padding: 0;
    text-align: center;
    margin: 50px 0 30px 0;
  }
}
