.RowTxtRight {
  display: flex;
  justify-content: center;
  margin: 0 0 25px 0;
}

.RowTxtRight--container {
  display: flex;
  justify-content: space-between;
  margin: 0;
  background-color: #f2f2f2;
  width: 1140px;
}

.RowTxtRight--Right {
  width: 412px;
  padding: 60px 70px 0 0;
}

.RowTxtRight--Left {
  display: flex;
  align-items: flex-end;
  padding-left: 160px;
}

.img-2 {
  object-fit: cover;
  height: 430px;
  width: 350px;
}

@media screen and (max-width: 480px) {
  .RowTxtRight--container {
    width: 90%;
  }

  .RowTxtRight--Left {
    display: none;
  }

  .RowTxtRight--Right {
    padding: 20px;
  }

  .RowTxtRight--Right > p {
    padding: 0;
  }
}
