.defaultbtn {
  background-color: #bd8983;
  color: white;
  padding: 8px 65px 8px 65px;
  font-family: "Neue-Haas-Unica-W1G", sans-serif;
  font-size: 18px;
  font-weight: 500;
  border-radius: 3px;
  border: none;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  outline: none;
  transition: ease 0.2s;
}

.defaultbtn:hover {
  box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.35);
  transform: scale(0.97);
  transition: ease 0.2s;
}
